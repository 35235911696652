import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SkillCard from "./SkillCard.js";

import javascript from "../assets/skills/javascript.svg";
import bash from "../assets/skills/bash.svg";
import linux from "../assets/skills/linux.svg";
import mikrotik from "../assets/skills/mikrotik.svg";
import windows from "../assets/skills/windows.svg";
import react from "../assets/skills/react.svg";
import docker from "../assets/skills/docker.svg";
import git from "../assets/skills/git.svg";
import express from "../assets/skills/express.svg";
import github from "../assets/skills/github-mark-white.svg";
import nodejs from "../assets/skills/nodejs.svg";
import goblue from "../assets/skills/goblue.svg";
import angular from "../assets/skills/angular.svg";
import digitalocean from "../assets/skills/digitalocean.svg";
import azure from "../assets/skills/microsoft-azure.svg";
import html from "../assets/skills/html.svg";
import css from "../assets/skills/css.svg";
import bootstrap from "../assets/skills/bootstrap.svg";
import tailwind from "../assets/skills/tailwind.svg";
import python from "../assets/skills/python.svg";
import csharp from "../assets/skills/csharp.svg";
import google from "../assets/skills/google-cloud.svg";
import arduino from "../assets/skills/arduino.svg";

export default function Skills() {
    const settings = {
        dots: false,
        autoplay: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1
      };

    return (
        <div id="skills" className="mt-4 text-white">
            <div style={{ height: '100px' }}></div>
            <h1 className="text-2xl font-bold">Skills</h1>
            {/* <p className="font-light text-gray-400">Here are some of my skills</p> */}
            <div className="mt-4">
                <Slider {...settings}>
                    <SkillCard name="HTML" experience="1 years" img={html} />
                    <SkillCard name="CSS" experience="1 years" img={css} />
                    <SkillCard name="JavaScript" experience="1 years" img={javascript} />
                    <SkillCard name="Bootstrap" experience="1 years" img={bootstrap} />
                    <SkillCard name="Tailwind" experience="1 years" img={tailwind} />
                    <SkillCard name="NodeJs" experience="1 years" img={nodejs} />
                    <SkillCard name="Express" experience="1 years" img={express} />
                    <SkillCard name="Golang" experience="1 years" img={goblue} />
                    <SkillCard name="React" experience="1 years" img={react} />
                    <SkillCard name="Angular" experience="1 years" img={angular} />
                    <SkillCard name="Python" experience="1 years" img={python} />
                    <SkillCard name="C-Sharp" experience="1 years" img={csharp} />
                    <SkillCard name="Docker" experience="1 years" img={docker} />
                    <SkillCard name="Git" experience="1 years" img={git} />
                    <SkillCard name="Github" experience="1 years" img={github} />
                    <SkillCard name="DigitalOcean" experience="1 years" img={digitalocean} />
                    <SkillCard name="Google Cloud" experience="1 years" img={google} />
                    <SkillCard name="Microsoft Azure" experience="1 years" img={azure} />
                    <SkillCard name="Arduino" experience="1 years" img={arduino} />
                    <SkillCard name="Mikrotik" experience="1 years" img={mikrotik} />
                    <SkillCard name="Linux" experience="1 years" img={linux} />
                    <SkillCard name="Bash" experience="1 years" img={bash} />
                    <SkillCard name="Windows" experience="1 years" img={windows} />
                </Slider>
            </div>
            <div style={{ height:"30px"}}></div>
        </div>
    )
}