import "./App.css";
import { useEffect, createContext } from "react"
import AOS from "aos";
import "aos/dist/aos.css";
import { RouterProvider } from "react-router-dom"
import router from "./routes/root";
const Context = createContext({});

function App() {
  useEffect(() => {
      document.title = "Cinallegam";
      AOS.init();
  }, []);
  return (
    <Context.Provider value={{}}>
      <RouterProvider router={router} />
    </Context.Provider>
  );
}

export { Context };
export default App;
