import { Link } from "react-router-dom";
import perfumeCover from "../assets/orpheon.png";
import introCover from "../assets/byredo_cover.png";
import { pathURL } from "../routes/root";

function Blog() {
    return (<div className="px-6 lg:px-20 xl:px-36 bg-dark-500">
        <div className="fixed z-50 bg-dark-500 w-full top-0 left-0 px-8 py-4 lg:px-20 xl:px-36">
            <div className="flex justify-between items-center text-white">
                <ul className="flex">
                    <li className="p-4">
                        <Link to={pathURL.main} className="flex gap-3 items-center hover:underline">
                            <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 12L4 12L10 18M7 9L10 6" stroke="#fff" />
                            </svg>
                            Return to Home
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
        <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
            <div>
                <div className="grid grid-cols-2 gap-5 w-full mt-8">
                    <div className="max-w-sm bg-dark-200 border border-gray-800 rounded-lg" data-aos="fade-right" data-aos-duration="1500" data-aos-offset="100">
                        <img className="rounded-t-lg" src={introCover} alt="introducing_cover" />
                        <div className="p-5">
                            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-300 dark:text-white">Introducing</h5>
                            <p className="mb-3 font-normal text-gray-400 dark:text-gray-400">เป็นเครื่องมือหรือแพลตฟอร์มดิจิทัลใดๆก็ตาม ที่ช่วยให้ผู้ใช้สามารถสร้างและ.....</p>
                            <Link to={pathURL.blogA} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                อ่านเพิ่ม
                                <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>    
                            </Link>
                        </div>
                    </div>  
                    <div className="max-w-sm bg-dark-200 border border-gray-800 rounded-lg" data-aos="fade-down" data-aos-duration="1500" data-aos-offset="100">
                        <img className="rounded-t-lg" src={perfumeCover} alt="orpheon" />
                        <div className="p-5">
                            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-300 dark:text-white">Things to know about perfume</h5>
                            <p className="mb-3 font-normal text-gray-400 dark:text-gray-400">
                                น้ำหอมเป็นองค์ประกอบหลักในการช่วยเสริมสร้างบุคลิก เสน่ห์ของ.....
                            </p>
                            <Link to={pathURL.blogB} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                อ่านเพิ่ม
                                <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>    
                            </Link>
                        </div>
                    </div>   
                </div>
            </div>
        </section>
    </div>)
}

export default Blog;