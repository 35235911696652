import byredoCover from "../assets/byredo_cover.png";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import PerfumerCover from "../assets/perfumer_cover.png"
import SocialCover from "../assets/social_cover.jpg";
import OverlookedSocial from "../assets/Overlooked-Social-Media.svg";
import profile from "../assets/profile.png";

function BlogSocial() {
    const ref = useRef();
    const sectionID = [];
    ref.count = 0
    useEffect(() => {
        let obj = {...ref.current.children}
        for(let i = 1; i < Object.keys(obj).length; i++) {
            let id = uuidv4().replaceAll("-", "");
            obj[i].id = id;
            sectionID.push(id);
        }
        ref.current.addEventListener("click", e => {
            const arr = Object.values(sectionID);
            window.location.hash = arr[ref.count++];
            if(ref.count === arr.length) ref.count = 0;
        })
        ref.count++;
    })
    
    return (<div ref={ref} onLoad={() => {}} className="px-6 lg:px-20 xl:px-36 bg-dark-500">
    <div className="fixed z-50 bg-dark-500 w-full top-0 left-0 px-8 py-4 lg:px-20 xl:px-36">
        <div className="flex justify-between items-center text-white">
            <ul className="flex">
                <li className="p-4">
                    <Link to={`/blog`} className="flex gap-3 items-center hover:underline">
                        <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 12L4 12L10 18M7 9L10 6" stroke="#fff" />
                        </svg>
                        Return to Blog
                    </Link>
                </li>
            </ul>
        </div>
    </div>

    <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
        <div className="md:w-6/6" data-aos="fade-in" data-aos-duration="3000" data-aos-offset="300">
            <h1 className="text-gray-400 text-5xl"><i>ทำไมน้ำหอมจึงมีความสำคัญต่อคนเรา ?</i></h1>
        </div>
    </section>

    <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
        <div className="md:w-3/7 md:p-4" data-aos="zoom-in" data-aos-delay="900" data-aos-duration="1500" data-aos-offset="200">
            <img className="rounded-3xl" width={500} src={byredoCover} alt="profile" />
        </div>
        <div className="md:w-3/6">
            <div className="flex flex-col w-full">
                <blockquote>
                    <p data-aos="fade-down" data-aos-delay="300" data-aos-duration="1500" data-aos-offset="100">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        หากพูดถึงน้ำหอม หลายๆคนคงปฎิเสธไม่ได้ว่าน้ำหอมเป็นส่วนหนึ่งใน ของใช้ประจำวันที่จำเป็น 
                        และสำหรับบางคน น้ำหอมเป็นตัวช่วยบ่งบอกถึงเอกลักษณ์หรือลักษณะของ Lifestyle อีกด้วย
                        เพราะน้ำหอมแต่ละกลิ่นนั้นจะมีกลิ่นเฉพาะตัวที่แตกต่างกัน ตัวอย่างเช่น ถ้าคุณเป็นผู้ชายที่ชอบเล่นกีฬาและมีความคล่องแคล่ว
                        กลิ่นน้ำหอมที่ผู้ชายลักษณะนี้มักจะชอบคือแนวสปอร์ต
                    </p>
                    <div className="ml-2" data-aos="fade-in" data-aos-delay="400" data-aos-duration="3000" data-aos-offset="300">
                        <small><i className="text-gray-400">{"(ตัวอย่าง เช่น Tom ford - Costa Azzurra Acqua)"}</i></small>
                    </div>
                    <p className="mt-4" data-aos="fade-left" data-aos-delay="600" data-aos-duration="1500" data-aos-offset="100">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        ทีนี้เราจะมาพูดถึงเรื่องที่ว่าทำไมน้ำหอมจึงมีความสำคัญต่อคนเรา - ทุกวันนี้ ไม่ว่าเราจะเดิน นั่ง หรือนอน ล้วนแต่ได้รับกลิ่นต่างๆมาโดยไม่ได้ตั้งใจ 
                        เพราะว่าทุกคนต้องหายใจ ซึ่งทำให้เราได้รับกลิ่นที่เกิดขึ้นรอบตัวตลอดเวลา การรับรู้กลิ่นเป็นเรื่องซับซ้อนและเป็นเรื่องที่น่าสนใจมาก เพราะคนเราสามารถแยกแยะกลิ่นได้หลากหลาย
                        และจากงานวิจัยพบว่า น้ำหอมและกลิ่นต่างๆส่งผลกับความรู้สึกของคนเราในหลายๆด้าน เช่น บางกลิ่นทำให้รู้สึกสดชื่น และบางกลิ่นทำให้รู้สึกผ่อนคลาย และน้ำหอมบางกลิ่นก็ช่วยปลุกความทรงจำในวันวานของเราขึ้นมาได้เช่นเดียวกัน
                    </p>
                </blockquote>
            </div>
        </div>
    </section>

    <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
        <div className="md:w-3/6">
            <div className="flex flex-col w-full">
                <blockquote>
                    <h1 className="text-2xl text-gray-300" data-aos="fade-down" data-aos-duration="1500" data-aos-offset="200">
                        <i><b>ตัวอย่างจากงานวิจัยที่เกี่ยวกับกลิ่นบางส่วนกล่าวไว้ว่า</b></i>
                        </h1>
                    <p className="ml-3 mt-4" data-aos="fade-right" data-aos-delay="300" data-aos-duration="1500" data-aos-offset="100">
                        —&nbsp; ผู้ที่ซื้อรถใหม่จะเชื่อมโยงความใหม่ของรถไปที่กลิ่นเป็นหลัก ซึ่งจริงๆแล้วบริษัทรถได้สร้างกลิ่นรถใหม่เทียมขึ้นมา โดยจะพ่นไปที่ตัวรถทั้งภายในและภายนอก ผู้ซื้อจะรู้สึกถึงความแตกต่างระหว่างรถใหม่กับรถเก่าได้จากกลิ่น
                    </p>
                    <p className="ml-3 mt-4" data-aos="fade-right" data-aos-delay="600" data-aos-duration="1500" data-aos-offset="100">
                        —&nbsp; การชมภาพยนตร์จะได้อรรถรสมากยิ่งขึ้น หากมีกลิ่นข้าวโพดคั่วตลบอบอวลอยู่บริเวณทางเข้าของโรงหนัง โดยผู้ชมอาจรู้สึกขาดสิ่งใดสิ่งหนึ่งไปหากไม่มีกลิ่นดังกล่าว
                    </p>
                    <p className="ml-3 mt-4" data-aos="fade-right" data-aos-delay="900" data-aos-duration="1500" data-aos-offset="100">
                        —&nbsp; ชุดชั้นใน Victoria Secret มีการใส่กลิ่นหอมของน้ำหอมผู้หญิงเข้าไปในชุดชั้นใน ทำให้สินค้าของพวกเขาเป็นที่จดจำ
                    </p>
                </blockquote>
            </div>
        </div>
        <div className="md:w-3/7 md:p-4" data-aos="zoom-in" data-aos-delay="900" data-aos-duration="1500" data-aos-offset="200">
            <img className="rounded-3xl" width={500} src={PerfumerCover} alt="profile" />
        </div>
    </section>

    <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
        <div className="md:w-6/6" data-aos="fade-in" data-aos-duration="3000" data-aos-offset="300" >
            <h1 className="text-gray-400 text-6xl"><i> { " ... "}</i></h1>
        </div>
    </section>

    <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
        <div className="md:w-6/6">
            <h1 className="text-gray-400 text-5xl" data-aos="fade-in" data-aos-delay="300" data-aos-duration="3000" data-aos-offset="300">
                <i>ประเภทโซเชียลมีเดีย (Social Media)</i>
            </h1>
            <h1 className="text-gray-400 text-5xl mt-6" data-aos="fade-in" data-aos-delay="900" data-aos-duration="3000" data-aos-offset="300">
                <i>และการเลือกใช้</i>
            </h1>
        </div>
    </section>

    <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
        <div className="md:w-3/7 md:p-4" data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1500" data-aos-offset="200">
            <img className="rounded-3xl" width={500} src={SocialCover} alt="profile" />
        </div>
        <div className="md:w-3/6">
            <div className="flex flex-col w-full">
                <blockquote>
                    <h1 className="text-2xl text-gray-300" data-aos="fade-down" data-aos-duration="1500" data-aos-offset="200">
                        <b><i><u>โซเชียลมีเดีย (Social Media)</u></i></b>
                    </h1>
                    <p className="ml-3 mt-6" data-aos="fade-left" data-aos-delay="300" data-aos-duration="1500" data-aos-offset="100">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        เป็นเครื่องมือหรือแพลตฟอร์มดิจิทัลใดๆก็ตามที่ช่วยให้ผู้ใช้สามารถสร้างและแชร์เนื้อหากับเพื่อน หรือสาธารณชนทั่วไปได้อย่างรวดเร็ว 
                        โดยที่เนื้อหาบนโซเชียลมีเดียนั้นค่อนข้างจะเปิดกว้าง และไม่มีเส้นแบ่งจำกัดว่าอะไรบ้างที่โพสได้หรือไม่ได้ (เฉพาะบางแพลตฟอร์ม) 
                        การแชร์ข่าวหรือเนื้อหาเป็นไปอย่างรวดเร็วและกว้างขวาง แต่ก็มีเรื่องที่ต้องระวัง เช่น เนื้อหาที่ล่อแหลมไม่เหมาะสม 
                        หรือการแชร์ข่าวที่เป็นเท็จ (Fake News)
                    </p>
                </blockquote>
            </div>
        </div>
    </section>

    <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
        <div className="md:w-3/7 md:p-4" data-aos="zoom-in" data-aos-delay="600" data-aos-duration="1500" data-aos-offset="200">
            <img className="rounded-3xl" width={500} src={OverlookedSocial} alt="profile" />
        </div>
        <div className="md:w-3/6">
            <div className="flex flex-col w-full">
                <blockquote>
                    <h1 className="text-2xl text-gray-300" data-aos="fade-down" data-aos-duration="1500" data-aos-offset="200">
                        <b><i>Social Media มีกี่ประเภท ?</i></b>
                    </h1>
                    <div className="ml-5" data-aos="fade-in" data-aos-delay="600"  data-aos-duration="1500" data-aos-offset="200">
                        <small>
                        ประเภทของโซเชียลมีเดียแบ่งออกได้เป็น 5 ประเภทใหญ่ บางอันไม่ได้มีลักษณะตายตัว เพราะบางโซเชียลมีเดียก็เป็นได้หลายๆอย่างในเวลาเดียวกัน
                        </small>
                    </div>
                    <div className="ml-5" data-aos="fade-left" data-aos-delay="300" data-aos-duration="1500" data-aos-offset="100">
                        <p className="text-gray-300 mt-6"><b><u><i>Social Media แบบ Social Networks</i></u></b></p>
                        <p className="ml-3">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            คือประเภทที่เชื่อมโยงผู้คนเอาไว้ด้วยการแลกเปลี่ยนความคิดเห็น ไอเดียต่างๆ หรือการติดตามผู้ที่มีความสนใจเดียวกันกับเรา เช่น Facebook, Instagram และ Linkined เป็นต้น
                        </p>
                    </div>
                    <div className="ml-5" data-aos="fade-left" data-aos-delay="600" data-aos-duration="1500" data-aos-offset="100">
                        <p className="text-gray-300 mt-6"><b><u><i>Social Media แบบ Media Networks</i></u></b></p>
                        <p className="ml-3">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            คือประเภทที่เอาไว้แชร์คอนเทนต์ที่เป็นมีเดียเป็นส่วนใหญ่ เช่น แชร์รูปภาพ-วิดีโอ เช่น Instagram, Vimeo และ Youtube เป็นต้น
                        </p>
                    </div>
                </blockquote>
            </div>
        </div>
    </section>

    <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
        <div className="md:w-3/7 md:p-4" data-aos="zoom-in" data-aos-delay="900" data-aos-duration="1500" data-aos-offset="200">
            <img className="rounded-3xl" width={500} src={OverlookedSocial} alt="profile" />
        </div>
        <div className="md:w-3/6">
            <div className="flex flex-col w-full">
                <blockquote>
                    <div data-aos="fade-left" data-aos-delay="300" data-aos-duration="1500" data-aos-offset="100">
                        <p className="text-gray-300"><b><u><i>Social Media แบบเน้น Discussions</i></u></b></p>
                        <p className="ml-3">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            คือประเภทที่เน้นการตั้งคำถามและพูดคุยกันเป็นหลัก ไม่ได้เน้นการติดตามกันเหมือนสองประเภทแรก แต่เน้นการแลกเปลี่ยนทัศนคติที่มีต่อหัวข้อเรื่องนั้นๆ เช่น Reddit, Quora หรือ Pantip.com เป็นต้น
                        </p>
                    </div>
                    <div className="mt-6" data-aos="fade-left" data-aos-delay="600" data-aos-duration="1500" data-aos-offset="100">
                        <p className="text-gray-300"><b><u><i>Social Media แบบเน้น Reviews</i></u></b></p>
                        <p className="ml-3">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            คือประเภทที่เน้นการรีวิวเป็นหลัก ไม่ว่าจะเป็นการรีวิวโรงแรม ร้านอาหาร สถานที่ท่องเที่ยว เพื่อเป็นไกด์ไลน์ให้ผู้ที่สนใจ ใช้ในการประกอบการตัดสินใจ เช่น TripAdvisor, Google My Business หรือ Wongnai เป็นต้น
                        </p>
                    </div>
                    <div className="mt-6" data-aos="fade-left" data-aos-delay="900" data-aos-duration="1500" data-aos-offset="100">
                        <p className="text-gray-300"><b><u><i>Social Media แบบ Micro</i></u></b></p>
                        <p className="ml-3">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            คือประเภทที่เน้นการการแชร์ข้อความสั้นๆ เน้นความฉับไวของการเลื่อนดูเนื้อหา เนื้อหาค่อนข้าง Freestyle จะเป็น Media หรือข้อความก็ได้ เช่น Twitter, Tumblr หรือ TikTok เป็นต้น
                        </p>
                    </div>
                </blockquote>
            </div>
        </div>
    </section>

    <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
        <div className="md:w-3/7 md:p-4" data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1500" data-aos-offset="200">
            <img style={{ borderRadius: "10%"}} width={300} src={profile} alt="profile" />
        </div>
        <div className="md:w-3/6">
            <div className="flex flex-col w-full">
                <blockquote>
                    <h1 className="text-2xl text-gray-300" data-aos="fade-down" data-aos-duration="1500" data-aos-offset="200">
                        <i><u><b>ประเภทโซเชียลมีเดีย (Social Media) ที่เลือกใช้</b></u></i>
                    </h1>
                    <p className="ml-3 mt-6" data-aos="fade-left" data-aos-delay="300" data-aos-duration="1500" data-aos-offset="200">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        จากที่ได้กล่าวมา เราได้เลือกใช้ Social Media แบบ Social Networks และสร้าง Facebook ของร้านขึ้นมา 
                        สำหรับใช้ในการโปรโมทและรีวิวเกี่ยวกับน้ำหอมและสินค้ากลุ่มแฟชั่น เนื่องจาก Facebook เป็นแพลทฟอร์มที่มีผู้ใช้งานอยู่จำนวนมากและในระบบหลังบ้านของเพจ Facebook 
                        มีเครื่องมือสำหรับวิเคราะห์สถิติจำนวนผู้เข้าชมสินค้าของเรา และมีบริการโปรโมทสินค้า เพื่อให้สินค้าของเราเข้าถึงลูกค้ากลุ่มต่างๆได้มากขึ้น
                    </p>
                </blockquote>
            </div>
        </div>
    </section>

    <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
        <div className="md:w-6/6" data-aos="fade-in" data-aos-duration="3000" data-aos-offset="100" >
            <h1 className="text-gray-400 text-6xl text-6xl mb-2"><i>{"Thank you :)"}</i></h1>
            <div className="ml-20" data-aos="fade-up" data-aos-duration="2000" data-aos-offset="200">
                <small className="text-gray-600">By Worapojn Mitrkhajorn (28 February 2023)</small>
            </div>
        </div>
    </section>

    <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
        <div className="md:w-3/6">
            <div className="flex flex-col w-full">
                <blockquote>
                    <h1 data-aos="fade-down" data-aos-duration="1500" data-aos-offset="200" className="text-4xl text-gray-400"><i><u><b>Credit</b></u></i></h1>
                    <br />
                    <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="500" data-aos-offset="200" className="ml-3 text-gray-300">
                        Pannakan Warawattananon. (2022). Social Media คืออะไร? มีกี่แบบ แล้วทำไมธุรกิจถึงต้องมี?. สืบค้นจากเว็บไซต์&nbsp;
                        <a href="https://urlzs.com/9F1aY" target="_blank" rel="noreferrer" className="hover:underline">https://urlzs.com/9F1aY</a>.
                    </p>
                    <br />
                    <p data-aos="fade-left" data-aos-duration="1500" data-aos-delay="1000" data-aos-offset="200" className="ml-3 text-gray-300">
                    Jamia Kenan. (2022). 11 overlooked social media benefits for business. สืบค้นจากเว็บไซต์&nbsp;
                        <a href="https://sproutsocial.com/insights/benefits-of-social-media/" target="_blank" rel="noreferrer" className="hover:underline">https://sproutsocial.com/insights/benefits-of-social-media/</a>.
                    </p>
                    <br />
                    <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="1500" data-aos-offset="200" className="ml-3 text-gray-300">
                    Emily Petsko. (2019). 11 Secrets of Perfumers. สืบค้นจากเว็บไซต์&nbsp;
                        <a href="https://www.mentalfloss.com/article/568190/secrets-perfumers" target="_blank" rel="noreferrer" className="hover:underline">https://www.mentalfloss.com/article/568190/secrets-perfumers</a>.
                    </p>
                </blockquote>
            </div>
        </div>
    </section>

</div>);
}

export default BlogSocial;