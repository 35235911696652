import perfume from "../assets/orpheon.png";
import pyramid from "../assets/pyramid-perfume.jpg";
import according from "../assets/according_perfume.png";
import concentration from "../assets/concentration-perfume.png";
import floral from "../assets/floral.png";
import oriental from "../assets/oriental.png";
import woody from "../assets/woody.png";
import fresh from "../assets/fresh.png";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import { v4 as uuidv4 } from 'uuid';

function BlogPerfume() {
    const ref = useRef();
    const sectionID = []
    ref.count = 0
    useEffect(() => {

        let obj = {...ref.current.children}
        for(let i = 1; i < Object.keys(obj).length; i++) {
            let id = uuidv4().replaceAll("-", "");
            obj[i].id = id;
            sectionID.push(id);
        }

        ref.current.addEventListener("click", e => {
            const arr = Object.values(sectionID);
            window.location.hash = arr[ref.count++];
            if(ref.count === arr.length) ref.count = 0;
        })
        ref.count++;
    })

    return (<div ref={ref} className="px-6 lg:px-20 xl:px-36 bg-dark-500">
        <div className="fixed z-50 bg-dark-500 w-full top-0 left-0 px-8 py-4 lg:px-20 xl:px-36">
            <div className="flex justify-between items-center text-white">
                <ul className="flex">
                    <li className="p-4">
                        <Link to={`/blog`} className="flex gap-3 items-center hover:underline">
                            <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 12L4 12L10 18M7 9L10 6" stroke="#fff" />
                            </svg>
                            Return to Blog
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
        <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
            <div className="md:w-3/7 md:p-4">
                <img className="rounded-3xl" width={500} data-aos="zoom-in" data-aos-duration="1500" data-aos-offset="200" src={perfume} alt="profile" />
            </div>
            <div className="md:w-3/6">
                <div className="flex flex-col w-full mt-8">
                    <blockquote>
                        <h1 data-aos="fade-down" data-aos-duration="1500" data-aos-offset="200" className="text-2xl text-gray-300"><i><b><u>น้ำหอม (Perfume)</u></b></i></h1>
                        <br />
                        <p data-aos="fade-left" data-aos-duration="1500" data-aos-offset="200" data-aos-delay="200" className="ml-3">
                            &nbsp;&nbsp;&nbsp;&nbsp;เป็นองค์ประกอบหลักในการช่วยเสริมสร้างบุคลิก เสน่ห์ของหลายๆคนไม่ได้อยู่ที่บุคลิกภาพและการแต่งตัวเพียงอย่างเดียว ความหอมของกลิ่นกายก็กลายเป็นส่วนหนึ่งของเอกลักษณ์เฉพาะตัวได้เช่นกัน 
                            ดังนั้น การทำความรู้จักและวิธีการใช้น้ำหอมอย่างถูกต้อง เพื่อให้เกิดกลิ่นหอมต่อร่างกายของเราจึงเป็นสิ่งสำคัญ
                        </p>
                    </blockquote>
                </div>
            </div>
        </section>

        <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
            <div className="md:w-6/6" data-aos="fade-in" data-aos-duration="2000" data-aos-offset="200" >
                <h1 className="text-gray-400 text-6xl"><i>สิ่งที่ควรรู้เกี่ยวกับน้ำหอม</i></h1>
            </div>
        </section>

        <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
            <div className="md:w-6/6 justify-center">
                <div data-aos="fade-down" data-aos-duration="1500" data-aos-offset="200">
                    <h1 className="text-gray-400 text-2xl"><i><b>โครงสร้างน้ำหอม (Notes)</b></i></h1>
                </div>
                <div data-aos="fade-up" data-aos-duration="1200" data-aos-offset="100">
                    <small className="ml-2">ประกอบไปด้วย 3 ชั้น</small>
                </div>
                <blockquote className="mt-2">
                    <div className="ml-3 mt-1" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="300" data-aos-offset="100">
                        <p className="text-gray-300"><b>—&nbsp; <u><i>Top Note (Head Note)</i></u></b></p>
                        <p className="ml-3">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            เป็นกลิ่นแรกที่จะสัมผัสได้ทันทีและค่อยๆจางหายไป, คงอยู่ได้นาน 5-15 นาที ส่วนใหญ่จะเป็นแนวกลิ่นสดชื่น เช่น ส้ม มะนาว หรือดอกไม้
                        </p>
                    </div>
                    <div className="ml-3 mt-1" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="600" data-aos-offset="100">
                        <p className="text-gray-300"><b>—&nbsp; <u><i>Middle Note (Heart Note)</i></u></b></p>
                        <p className="ml-3">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            เป็นกลิ่นหลักของน้ำหอมและเป็นตัวเชื่อมระหว่าง Top Note และ Base Note, คงอยู่ได้นาน 3-6 ชั่วโมง ส่วนใหญ่จะเป็นกลิ่นไม้หอม วานิลลา และดอกไม้ที่หายาก
                        </p>
                    </div>
                    <div className="ml-3 mt-1" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="900" data-aos-offset="100">
                        <p className="text-gray-300"><b>—&nbsp; <u><i>Base Note</i></u></b></p>
                        <p className="ml-3">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            เป็นกลิ่นสุดท้ายของน้ำหอม จะผสมผสานเข้ากับกลิ่นกายของผู้ใช้ ทำให้กลายเป็นกลิ่นที่มีเอกลักษณ์, คงอยู่ได้นาน 6-8 ชั่วโมง ส่วนใหญ่จะเป็นกลิ่นแนวมัสก์หรืออำพัน
                        </p>
                    </div>
                </blockquote>
                {/* <div className="flex flex-col w-full mt-8">
                    <h1 className="text-xl text-gray-300"><i>Test</i></h1>
                </div> */}
            </div>
            <div className="md:w-3/7 md:p-4">
                <img className="rounded-3xl" width={1000} data-aos="zoom-in" data-aos-delay="900" data-aos-duration="1500" data-aos-offset="200" src={pyramid} alt="profile" />
            </div>
        </section>

        <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
            <div className="md:w-6/6" >
                <h1 className="text-gray-400 text-2xl" data-aos="fade-down" data-aos-duration="1500" data-aos-offset="200">
                    <i><b>ความเข้มข้นของน้ำหอม (Concentration)</b></i>
                </h1>
                <div data-aos="fade-up" data-aos-duration="1200" data-aos-offset="100">
                    <small className="ml-2">ประกอบไปด้วย 5 ระดับ</small>
                </div>
                <blockquote className="mt-1.5">
                    <div className="ml-3 mt-1" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1500" data-aos-offset="100">
                        <p className="text-gray-300"><b>—&nbsp; <u><i>Parfum หรือ Perfume</i></u></b></p>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;มีหัวน้ำหอม 20-40%, ติดทน 8-10 ชั่วโมง เป็นชนิดที่ติดทนและราคาแพงที่สุด เหมาะกับผิว Sensitive เพราะมีแอลกอฮอล์ต่ำ
                        </p>
                    </div>
                    <div className="ml-3 mt-1" data-aos="fade-right" data-aos-delay="400" data-aos-duration="1500" data-aos-offset="100">
                        <p className="text-gray-300"><b>—&nbsp; <u><i>Eau de Parfum (EDP)</i></u></b></p>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;มีหัวน้ำหอม 15-20%, ติดทน 7-8 ชั่วโมง ราคาถูกกว่า Parfum และติดทนน้อยกว่าเพราะมีแอลกอฮอล์มากกว่า
                        </p>
                    </div>
                    <div className="ml-3 mt-1" data-aos="fade-right" data-aos-delay="600" data-aos-duration="1500" data-aos-offset="100">
                        <p className="text-gray-300"><b>—&nbsp; <u><i>Eau de Toilette (EDT)</i></u></b></p>
                        <p className="ml-3">
                            &nbsp;&nbsp;&nbsp;&nbsp;มีหัวน้ำหอม 5-15%, ราคาถูกกว่า EDP และเป็นประเภทที่นิยมที่สุด ติดทน 4-6 ชั่วโมง
                        </p>
                    </div>
                    <div className="ml-3 mt-1" data-aos="fade-right" data-aos-delay="800" data-aos-duration="1500" data-aos-offset="100">
                        <p className="text-gray-300"><b>—&nbsp; <u><i>Eau de Cologne (EDC)</i></u></b></p>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;มีหัวน้ำหอม 2-4%, ทำให้ราคาค่อนข้างถูก ติดทน 3-4 ชั่วโมง
                        </p>
                    </div>
                    <div className="ml-3 mt-1" data-aos="fade-right" data-aos-delay="1000" data-aos-duration="1500" data-aos-offset="100">
                        <p className="text-gray-300"><b>—&nbsp; <u><i>Eau Fraiche</i></u></b></p>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;มีหัวน้ำหอม 1-3%, ติดทน 2-3 ชั่วโมง และไม่มีส่วนประกอบของแอลกอฮอล์เพราะใช้น้ำแทน
                        </p>
                    </div>
                </blockquote>
            </div>
            <div className="md:w-3/7 md:p-4" data-aos="fade-left" data-aos-delay="1200" data-aos-duration="1500" data-aos-offset="200">
                <img className="rounded-3xl" width={800} src={concentration} alt="profile" />
            </div>
        </section>

        <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
            <div className="md:w-6/6 justify-center" data-aos="slide-left" data-aos-duration="1000" data-aos-offset="100">
                <h1 className="text-gray-400 text-5xl"><i>ประเภทของกลิ่นน้ำหอม</i></h1>
                <h1 className="text-gray-400 text-5xl"><i>(Fragrance wheel)</i></h1>
            </div>
            <div className="md:w-3/7 md:p-4" data-aos="flip-left" data-aos-duration="1500" data-aos-offset="200">
                <img style={{ borderRadius: "48%", backgroundColor: "#E6E6E6", padding: "1rem"}} width={500} src={according} alt="pyramid_perfume" />
            </div>
        </section>

        <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
            <div className="md:w-6/6 justify-center" data-aos="fade-right" data-aos-duration="1500" data-aos-offset="200">
                <blockquote>
                    <p data-aos="fade-down" data-aos-duration="1500" data-aos-offset="200" className="text-gray-300 text-xl mb-2">
                        <b><u><i>ประเภท Floral</i></u></b>
                    </p>
                    <p data-aos="fade-left" data-aos-duration="1300" data-aos-offset="200" className="ml-2">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        กลุ่มนี้เป็นหนึ่งในกลุ่มน้ำหอมที่ได้รับความนิยมมากที่สุด เป็นกลิ่นที่ค่อนข้างกว้าง และให้กลิ่นที่หอมหวาน
                    </p>
                    <div data-aos="fade-up" data-aos-duration="1200" data-aos-offset="100">
                        <small className="ml-3">โดยแบ่งเป็น 3 กลุ่มย่อย ได้แก่</small>
                    </div>
                    <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="300" data-aos-offset="100" className="ml-5 mt-3"> —&nbsp; <b className="text-gray-200">Floral</b> : เป็นกลิ่นที่เหมือนดอกไม้ที่เพิ่งตัดใหม่ เช่นกลิ่นของกุหลาบ หรือลิลลี่</p>
                    <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="600" data-aos-offset="100" className="ml-5 mt-3"> —&nbsp; <b className="text-gray-200">Soft Floral</b> : เป็นกลิ่นหอมที่บางเบา โดยจะเป็นกลิ่นหอมของดอกไม้อ่อนๆ ปนกับกลิ่นคล้ายๆแป้ง เช่น ดอกมะลิ</p>
                    <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="900" data-aos-offset="100" className="ml-5 mt-3 mb-3"> —&nbsp; <b className="text-gray-200">Floral Oriental</b> : กลิ่นหอมของดอกไม้ที่มีกลิ่นเครื่องเทศอ่อนๆ เพื่อลูกเล่นที่แปลกใหม่</p>
                    <small data-aos="fade-left" data-aos-duration="1500" data-aos-delay="1200" data-aos-offset="200" className="text-gray-400"><i>{"(ตัวอย่าง Maison Francis Kurkdjian - A La Rose)"}</i></small>
                </blockquote>
            </div>
            <div className="md:w-3/7 md:p-4">
                <img className="rounded-3xl" width={400} data-aos="zoom-in" data-aos-delay="1200" data-aos-duration="1500" data-aos-offset="200" src={floral} alt="profile" />
            </div>
        </section>

        
        <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
            <div className="md:w-6/6 justify-center" data-aos="fade-right" data-aos-duration="1500" data-aos-offset="200" >
                <blockquote>
                    <p data-aos="fade-down" data-aos-duration="1500" data-aos-offset="200" className="text-gray-300 text-xl mb-2">
                        <b><u>ประเภท Oriental</u></b>
                    </p>
                    <p data-aos="fade-left" data-aos-duration="1300" data-aos-offset="200" className="ml-2">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        กลุ่มนี้มีกลิ่นที่เข้มข้นอย่างกลิ่นของเครื่องเทศและสมุนไพร ดังนั้นกลุ่มนี้จึงให้ความรู้สึกที่ซาบซ่ายั่วยวนและอบอุ่น แต่ในขณะเดียวกันก็ยังมีความหอมหวานจึงทำให้กลิ่นนุ่มนวลขึ้น
                    </p>
                    <div data-aos="fade-up" data-aos-duration="1200" data-aos-offset="100">
                        <small className="ml-3">โดยแบ่งเป็น 3 กลุ่มย่อย ได้แก่</small>
                    </div>
                    <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="300" data-aos-offset="100" className="ml-5 mt-3"> —&nbsp; <b className="text-gray-200">Soft Oriental</b> : เป็นกลิ่นของควันหอมที่ผสมผสานกับเครื่องเทศอันแสนจะอบอุ่น เช่น โป๊ยกั๊ก</p>
                    <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="600" data-aos-offset="100" className="ml-5 mt-3"> —&nbsp; <b className="text-gray-200">Oriental</b> : เป็นกลิ่นหอมหวาน อบอุ่น โดดเด่นด้วยกลิ่นเผ็ดและมีความหรูหรา เช่น อบเชย มัสค์ วานิลลา</p>
                    <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="900" data-aos-offset="100" className="ml-5 mt-3 mb-3"> —&nbsp; <b className="text-gray-200">Woody Oriental</b> : เป็นการผสมผสานของกลิ่นเผ็ดและกลิ่นเอิร์ธโทน เพื่อเพิ่มความลึก เช่น ไม้จันทน์ ไม้โรสวูด</p>
                    <small data-aos="fade-left" data-aos-duration="1500" data-aos-delay="1200" data-aos-offset="200" className="text-gray-400"><i>{"(ตัวอย่าง Amouage - Amouage Myths)"}</i></small>
                </blockquote>
            </div>
            <div className="md:w-3/7 md:p-4" data-aos="zoom-in" data-aos-delay="1200" data-aos-duration="1500" data-aos-offset="200">
                <img className="rounded-3xl" width={400} src={oriental} alt="profile" />
            </div>
        </section>

        <section className="flex w-full flex-col h-screen md:flex-row gap-5 items-center justify-center text-white relative">
            <div className="md:w-6/6 justify-center" data-aos="fade-right" data-aos-duration="1500" data-aos-offset="200">
                <blockquote>
                    <p data-aos="fade-down" data-aos-duration="1500" data-aos-offset="200" className="text-gray-300 text-xl mb-2">
                        <b><u>ประเภท Woody</u></b>
                    </p>
                    <p data-aos="fade-left" data-aos-duration="1300" data-aos-offset="200" className="ml-2">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        กลุ่มนี้ให้กลิ่นที่อบอุ่นด้วยกลิ่นไม้ แต่ในบางครั้งก็มีการนำมาผสมผสานกับกลิ่นดอกไม้หรือกลิ่นซิตรัส ฉะนั้นแล้วสำหรับกลิ่นในกลุ่มนี้จึงให้ทั้งความอบอุ่น ความน่าค้นหาและน่าหลงใหล
                    </p>
                    <div data-aos="fade-up" data-aos-duration="1200" data-aos-offset="100">
                        <small className="ml-3">โดยแบ่งเป็น 3 กลุ่มย่อย ได้แก่</small>
                    </div>
                    <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="300" data-aos-offset="100" className="ml-5 mt-3"> —&nbsp; <b className="text-gray-200">Woods</b> : เป็นกลิ่นหลักในตระกูลนี้ โดยจะอิงจากกลิ่นต่าง ๆ เช่น ไม้ซีดาร์ ไม้จันทน์ และหญ้าแฝก น้ำหอมกลิ่นไม้บางชนิดอาจมีกลิ่นซิตรัสหรือกลิ่นดอกไม้เพื่อลดความอบอุ่นของกลิ่นโน๊ตไม้</p>
                    <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="600" data-aos-offset="100" className="ml-5"> —&nbsp; <b className="text-gray-200">Mossy Woods</b> : เป็นกลิ่นแนวเอิร์ธโทนที่นุ่มนวล เช่น โอ๊คมอส อำพัน</p>
                    <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="900" data-aos-offset="100" className="ml-5 mt-3"> —&nbsp; <b className="text-gray-200">Dry Woody</b> : เป็นกลิ่นของไม้แห้งๆ ผสมกับกลิ่นของเครื่องหนัง เช่น กลิ่นไม้จันทน์</p>
                    <small data-aos="fade-left" data-aos-duration="1500" data-aos-delay="1000" data-aos-offset="200" className="text-gray-400"><i>{"(ตัวอย่าง D.S. And Durga - Burning Barbershop)"}</i></small>
                </blockquote>
            </div>
            <div className="md:w-3/7 md:p-4" data-aos="zoom-in" data-aos-delay="1000" data-aos-duration="1500" data-aos-offset="200">
                <img className="rounded-3xl" width={400} src={woody} alt="woody" />
            </div>
        </section>

        <section className="flex w-full flex-col h-screen md:flex-row gap-5 items-center justify-center text-white relative">
            <div className="md:w-6/6 justify-center" data-aos="fade-right" data-aos-duration="1500" data-aos-offset="200">
                <blockquote>
                    <p data-aos="fade-down" data-aos-duration="1500" data-aos-offset="200" className="text-gray-300 text-xl mb-2">
                        <b><u>ประเภท Fresh</u></b>
                    </p>
                    <p data-aos="fade-left" data-aos-duration="1300" data-aos-offset="200" className="ml-2">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        กลุ่มนี้ค่อนข้างจะโดดเด่นในเรื่องกลิ่นที่ให้ความรู้สึกสะอาดและสดชื่นเป็นหลัก โดยอาจจะมีการนำไปผสมกับกลิ่นเครื่องเทศบ้างเพื่อที่จะให้กลิ่นนั้นมีความโดดเด่นมากขึ้น
                    </p>
                    <div data-aos="fade-up" data-aos-duration="1200" data-aos-offset="100">
                        <small className="ml-3">โดยแบ่งเป็น 5 กลุ่มย่อย ได้แก่</small>
                    </div>
                    <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="400" data-aos-offset="100" className="ml-5 mt-3"> —&nbsp; <b className="text-gray-200">Aromatic</b> : เป็นกลิ่นที่ให้ความรู้สึกถึงธรรมชาติจากกลิ่นของสมุนไพรที่ผสมด้วยกลิ่นของไม้หรือดอกไม้ เช่น ใบเสจ</p>
                    <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="600" data-aos-offset="100" className="ml-5 mt-3"> —&nbsp; <b className="text-gray-200">Citrus</b> : เป็นกลิ่นผลไม้ตระกูลส้มที่หอมสดชื่นมากๆ เช่น เลม่อน มะกรูด ส้มโอจีน</p>
                    <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="800" data-aos-offset="100" className="ml-5 mt-3"> —&nbsp; <b className="text-gray-200">Water</b> : เป็นกลิ่นของละอองทะเล มหาสมุทร ฝน หรือกลิ่นของอากาศหลังจากฝนตก ให้ความรู้สึกสดชื่นสบายตัว</p>
                    <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="900" data-aos-offset="100" className="ml-5 mt-3"> —&nbsp; <b className="text-gray-200">Green</b> : ให้ความรู้สึกถึงกลิ่นของหญ้าที่เพิ่งตัดใหม่ หรือกลิ่นของใบไม้สีเขียว เช่น กลิ่นหญ้า</p>
                    <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="1000" data-aos-offset="100" className="ml-5 mt-3"> —&nbsp; <b className="text-gray-200">Fruity</b> : เป็นกลิ่นของผลไม้ ที่มีทั้งความหวานฉ่ำและความทรอปิคอล เช่น แอปเปิ้ล พีช ลูกแพร์ ส้มแมนดาริน และมะเดื่อ</p>
                    <small data-aos="fade-left" data-aos-duration="1500" data-aos-delay="1100" data-aos-offset="200" className="text-gray-400"><i>{"(ตัวอย่าง Creed - Silver Mountain Water)"}</i></small>
                </blockquote>
            </div>
            <div className="md:w-3/7 md:p-4" data-aos="zoom-in" data-aos-delay="1100" data-aos-duration="1500" data-aos-offset="200">
                <img className="rounded-3xl" width={400} src={fresh} alt="fresh" />
            </div>
        </section>

        <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
            <div className="md:w-6/6">
                <h1 className="text-gray-400 text-6xl mb-1.5" data-aos="fade-in" data-aos-duration="3000" data-aos-offset="200">
                    <i>{"Thank you :)"}</i>
                </h1>
                <div data-aos="fade-up" data-aos-duration="2000" data-aos-offset="200">
                    <small className="text-gray-600 ml-20">By Worapojn Mitrkhajorn (28 February 2023)</small>
                </div>
            </div>
        </section>

        <section className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
            <div className="md:w-3/6">
                <div className="flex flex-col w-full">
                    <blockquote>
                        <h1 data-aos="fade-down" data-aos-duration="1500" data-aos-offset="200" className="text-4xl text-gray-400"><i><u><b>Credit</b></u></i></h1>
                        <br />
                        <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="500" data-aos-offset="200" className="ml-3 text-gray-300">
                            Admin Akumu. (2019). ศาสตร์แห่งกลิ่น เลือกใช้น้ำหอมอย่างไรให้เหมาะกับตัวเอง?. สืบค้นจากเว็บไซต์&nbsp;
                            <a href="https://thebeautrium.com/blog/05-14-2019-perfume-ep-1" target="_blank" rel="noreferrer" className="hover:underline">https://thebeautrium.com/blog/05-14-2019-perfume-ep-1</a>.
                        </p>
                        <br />
                        <p data-aos="fade-left" data-aos-duration="1500" data-aos-delay="1000" data-aos-offset="200" className="ml-3 text-gray-300">
                        Beauty Editor. (2019). Perfume 101 เปิดโลกน้ำหอม พร้อมวิธีเลือกกลิ่นที่เหมาะกับตัวเอง. สืบค้นจากเว็บไซต์&nbsp;
                            <a href="https://www.wongnai.com/articles/about-perfume" target="_blank" rel="noreferrer" className="hover:underline">https://www.wongnai.com/articles/about-perfume</a>.
                        </p>
                        <br />
                        <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="1500" data-aos-offset="200" className="ml-3 text-gray-300">
                        Admin. (2019). EAU DE PARFUM ORPHÉON 75ML. สืบค้นจากเว็บไซต์&nbsp;
                            <a href="https://www.diptyqueparis.com/fr_fr/p/eau-de-parfum-orpheon-75ml.html" target="_blank" rel="noreferrer" className="hover:underline">https://www.diptyqueparis.com/fr_fr/p/eau-de-parfum-orpheon-75ml.html</a>.
                        </p>
                    </blockquote>
                </div>
            </div>
        </section>

    </div>);
}

export default BlogPerfume;