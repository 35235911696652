import CertCard from "./CertCard.js";
import certA from "../assets/certs/futureskill-html5-css3-javascript.png";
import certB from "../assets/certs/futureskill-react-chat-application.png";
import certC from "../assets/certs/borntodev-essential-sql.png";
import certD from "../assets/certs/borntodev-get-started-azure-app-service.png";
import certE from "../assets/certs/borntodev-intro-to-computer-science.png";
import certF from "../assets/certs/udemy-typescript.png";

export default function Certs() {
    return (
        <div id="certs" className="mt-4 text-white">
            <div style={{ height: "100px" }}></div>
            <h1 className="text-2xl font-bold">Certifications</h1>
            {/* <p className="font-light text-gray-400">Here are some of my Certifications</p> */}
            <div className="grid grid-cols-1 md:grid-cols-3 justify-center mt-4 gap-5">
                <CertCard name="สร้างเว็บไซต์จากเริ่มต้นจนถึงมือโปร ด้วย HTML5/CSS3/JavaScript" img={certA} issued="Futureskill" date="10 Oct 2022" />
                <CertCard name="React ตั้งแต่พื้นฐาน จนสร้าง Chat Application ได้" img={certB} issued="Futureskill" date="6 Oct 2022" />
                <CertCard name="Essential SQL for Everyone" img={certC} issued="BorntoDev" date="23 Dec 2022" />
                <CertCard name="Get started with Azure App Service" img={certD} issued="BorntoDev" date="10 Oct 2022" />
                <CertCard name="Introduction to Computer Science" img={certE} issued="BorntoDev" date="23 Dec 2022" />
                <CertCard name="เจาะลึก TypeScript ตั้งแต่เริ่มต้นจนใช้งานจริง" img={certF} issued="Udemy" date="23 Dec 2022" />
            </div>
            <div style={{ height: "30px" }}></div>
        </div>
    )
}
