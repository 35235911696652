import { createBrowserRouter } from "react-router-dom";
import Blog from "../components/Blog";
import Main from "../components/Main";
import BlogPerfume from "../components/BlogPerfume";
import BlogIntro from "../components/BlogIntro";

const pathURL = {
  main: "/",
  blog: "/blog",
  blogA: "/blog/intro",
  blogB: "/blog/perfume",
}

const router = createBrowserRouter([
  {
    path: pathURL.main,
    element: <Main />,
  },
  {
    path: pathURL.blog,
    element: <Blog />
  },
  {
    path: pathURL.blogA,
    element: <BlogIntro />
  },
  {
    path: pathURL.blogB,
    element: <BlogPerfume />
  }
]);

export { pathURL };
export default router;