import profile from "../assets/profile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight, faLocationDot, faGraduationCap, faBriefcase, faIdCard } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedinIn, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { pathURL } from "../routes/root";

export default function Bio () {
    return (<>
        <div id="home" className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
            <div className="md:w-3/7 md:p-4">
                <img data-aos="flip-right" data-aos-duration="1500" data-aos-offset="200" src={profile} alt="profile" />
            </div>
            <div className="md:w-3/6" data-aos="fade-right" data-aos-duration="1000" data-aos-offset="100" >
                <div className="flex flex-col w-full mt-8">
                    <h1 className="text-xl text-gray-400">Hello, it's me</h1>
                    <h1 className="text-2xl font-bold mb-2">Cinallegam</h1>
                    <p className="text-xl font-bold text-gray-300 mb-2">
                        <FontAwesomeIcon icon={faBriefcase} />{" "}
                        Full-Stack Developer
                    </p>
                    <p className="text-md font-light text-gray-400 pl-1">
                        <FontAwesomeIcon icon={faLocationDot} />
                        <a href="https://goo.gl/maps/3BxPFuPMWu2oH5gM7" target="_blank" rel="noreferrer">
                        &nbsp;&nbsp;Khon kaen, Thailand
                        </a>
                    </p>
                    <p className="text-md font-light text-gray-400">
                        <FontAwesomeIcon icon={faGraduationCap} />{" "}
                        Bachelor of Technology Program in Digital Business Innovation{" "}
                        <a className="hover:underline" href="http://www.khonkaen.spu.ac.th/spu/" target="_blank" rel="noreferrer">
                            @SPU
                        </a>
                    </p>
                </div>
                <Link className="mt-2 mb-2 block hover:underline" to={pathURL.blog}>
                    Go to My Blog 
                    <FontAwesomeIcon className="ml-2" icon={faCircleArrowRight}/> 
                </Link>
                <ul className="flex mt-3 gap-4 items-center">
                    {/* <li>
                        <a className="hover:border-b-2 pb-2" href="/" rel="noreferrer" target="_blank">
                            <FontAwesomeIcon size="2xl" icon={faIdCard} />
                        </a>
                    </li> */}
                   <li>
                        <a className="hover:border-b-2 pb-2" href="https://github.com/cinallegam" rel="noreferrer" target="_blank">
                            <FontAwesomeIcon size="2xl" icon={faGithub} />
                        </a>
                   </li> 
                    <li >
                        <a className="hover:border-b-2 pb-2" href="https://www.linkedin.com/in/cinallegam/" rel="noreferrer" target="_blank">
                            <FontAwesomeIcon size="2xl" icon={faLinkedinIn} />
                        </a>
                    </li>
                    <li>
                        <a className="hover:border-b-2 pb-2" href="https://www.instagram.com/ter.wrp/" rel="noreferrer" target="_blank">
                            <FontAwesomeIcon size="2xl" icon={faInstagram} />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </>)
}
